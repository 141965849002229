.three-columns-products {
  display: flex;
  width: 90%;
  margin: 0 5%;
}

.products-columns {
  width: 33%;
}

.product-wrapper {
  text-align: center;
  padding: 1.5rem 0;
  filter: url("#goo");
  align-items: center;
}

.product-button {
  width: 50%;
  color: white;
  display: inline-block;
  text-align: center;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  padding: 1em 0;
  line-height: 1;
  border-radius: 4px;
  position: relative;
  min-width: 8.23em;
  text-decoration: none;
  font-size: 0.85rem;
  border: 2px solid hsl(204, 100%, 64%);
  background-color: hsl(204, 100%, 64%);
  cursor: pointer;
}

.productheader {
  width: 100%;
  margin-bottom: 5%;
  height: 570px;
}

.product-button:hover {
  border: 2px solid hsl(204, 100%, 64%);

  color: #0071bc;
  background-color: white;
}

.product-button:before,
.product-button:after {
  width: 4.4em;
  height: 2.95em;
  position: absolute;
  content: "";
  display: inline-block;
  background: white;
  border-radius: 50%;
  transition: transform 0.5s ease;
  transform: scale(0);
  z-index: -1;
  border: 2px solid hsl(204, 100%, 64%);
  color: white;
}

.product-button:before {
  top: -25%;
  left: 20%;
}

.product-button:after {
  bottom: -25%;
  right: 20%;
}

.product-button:hover:before,
.product-button:hover:after {
  transform: none;
}

@media only screen and (min-width: 1800px) {
  .productheader {
    height: 1100px;
  }
}

@media only screen and (max-width: 1799px) {
  .productheader {
    height: 1050px;
  }
}

@media only screen and (max-width: 1650px) {
  .productheader {
    height: 950px;
  }
}

@media only screen and (max-width: 1550px) {
  .productheader {
    height: 850px;
  }
}

@media only screen and (max-width: 1400px) {
  .productheader {
    height: 750px;
  }
}

@media only screen and (max-width: 1200px) {
  .productheader {
    height: 650px;
  }
}

@media only screen and (max-width: 1000px) {
  .productheader {
    height: 550px;
  }
}

@media only screen and (max-width: 850px) {
  .products-columns {
    width: 100%;
  }
  .product-button {
    width: 80%;
  }

  .productheader {
    height: 450px;
  }
}

@media only screen and (max-width: 800px) {
  .products-columns {
    width: 100%;
  }
  .product-button {
    width: 80%;
  }
}

@media only screen and (max-width: 650px) {
  .productheader {
    height: 375px;
  }
}

@media only screen and (max-width: 600px) {
  .three-columns-products {
    display: inline-block;
    width: 90%;
    margin: 0 5%;
  }

  .products-columns {
    width: 100%;
  }
  .product-button {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .productheader {
    height: 300px;
  }
}

@media only screen and (max-width: 450px) {
  .productheader {
    height: 200px;
  }
}
