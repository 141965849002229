.footer {
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 129px;
  background-color: hsl(204, 100%, 64%);
  color: blue;
  border-bottom: 1px solid hsl(204, 100%, 64%);
  opacity: 100%;
}

.socialbar {
  width: 14%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-evenly;
}

.social-logo {
  justify-content: space-evenly;
  height: 25px;
}

.copyright {
  font-size: 10px;
  color: white;
  display: inline;
  text-align: center;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 950px) {
  .socialbar {
    width: 17%;
  }
  /**
  .socialbar {
    width: 40%;
  }

  .social-logo {
    justify-content: space-evenly;
    height: 40px;
  }
  */
}

@media only screen and (max-width: 700px) {
  .socialbar {
    width: 21%;
  }
  /**
  .socialbar {
    width: 40%;
  }

  .footer {
    height: 190px;
  }*/
}

@media only screen and (max-width: 500px) {
  .socialbar {
    width: 35%;
  }
  /**
  .socialbar {
    width: 50%;
  }

  .footer {
    height: 170px;
  }

  .social-logo {
    height: 30px;
  }*/
}

@media only screen and (max-width: 330px) {
  .socialbar {
    width: 45%;
  }
  /**
  .socialbar {
    width: 70%;
  }

  .footer {
    height: 170px;
  }

  .social-logo {
    height: 25px;
  }*/
}
