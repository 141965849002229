.Header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 250px;
  background-color: white;
  color: blue;
  border-bottom: 1px solid hsl(204, 100%, 64%);
  z-index: 100;
}

.webLogo {
  height: 85%;
  position: absolute;
  left: 5%;
  bottom: 0;
  margin: auto 0;
}

.menuMobileDisplay {
  display: none;
}

.menuLogo.MuiSvgIcon-root {
  color: black;
  font-size: 50px;
  position: absolute;
  top: 10%;
  right: 4%;
}

.language-box {
  width: 15%;
  display: flex;
  position: absolute;
  top: 10%;
  right: 4%;
  justify-content: space-evenly;
}

.language-img {
  height: 40px;
}

.language-btn {
  position: absolute;
  top: 10%;
  right: 0%;
  font-size: 100%;
  font-family: "Raleway", sans-serif;
  font-weight: 200;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .menuDeskDisplay {
    display: none;
  }

  .webLogo {
    height: 75%;
  }

  .menuMobileDisplay {
    display: inline;
  }

  .menuLogo.MuiSvgIcon-root {
    position: absolute;
    top: 3%;
    right: 4%;
  }

  .language-img {
    top: 80%;
  }

  .language-box {
    top: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .menuDeskDisplay {
    display: none;
  }

  .webLogo {
    height: 75%;
  }

  .menuMobileDisplay {
    display: inline;
  }

  .menuLogo.MuiSvgIcon-root {
    position: absolute;
    top: 3%;
    right: 4%;
  }

  .language-img {
    top: 80%;
  }

  .language-box {
    top: 80%;
  }
}
