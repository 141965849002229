.InfoBlockDeskCard1 {
  width: 100%;
  height: 750px;
  overflow: hidden;
  margin: 0;
  text-align: center;
  display: inline-block;
  background-position: 1px;
  background-size: cover;
  -webkit-filter: grayscale(0.8);
  filter: grayscale(0.8);
  cursor: pointer;
  text-decoration: none;
  vertical-align: middle;
  object-fit: cover;
  margin-bottom: 0;
  background-image: url("../../../assets/carousel-imgs/p15.png");
}

.InfoBlockDeskImageBtn {
  background-color: transparent;
  font-size: 30px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  padding: 1% 4%;
  border-radius: 50px;
  border: 1px solid white;
  color: white;
  text-transform: uppercase;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-us-row {
  text-align: center;
  width: 100%;
  height: 400px;
  display: flex;
  margin: 0;
}

.about-us-text-block {
  align-content: center;
  width: 50%;
  height: 100%;
  margin: 0;
  text-align: justify;
  text-justify: inter-word;
  margin-top: 3%;
  padding-top: 30px;
}

.about-us-img-block {
  width: 50%;
  height: 100%;
  margin: 0;
  background-size: cover;
  align-content: center;
  object-fit: cover;
}

.about-us-text-block-title {
  font-weight: 400;
  margin: auto;
  color: #3a3a3a;
  font-size: 200%;
  margin-bottom: 45px;
  margin-right: 10%;
  font-family: "Raleway", sans-serif;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.about-us-text-block-desc {
  font-weight: 200;
  margin: auto 10%;
  font-size: 120%;
  font-family: "Raleway", sans-serif;
}

@media only screen and (min-width: 1800px) {
  .InfoBlockDeskCard1 {
    height: 1150px;
  }

  .about-us-row {
    height: 700px;
  }

  .about-us-text-block-desc {
    font-size: 205%;
  }
}

@media only screen and (max-width: 1799px) {
  .about-us-row {
    height: 600px;
  }
  .about-us-text-block-desc {
    font-size: 180%;
  }
}
@media only screen and (max-width: 1700px) {
  .about-us-row {
    height: 650px;
  }
}

@media only screen and (max-width: 1600px) {
  .about-us-row {
    height: 600px;
  }
}

@media only screen and (max-width: 1500px) {
  .about-us-row {
    height: 550px;
  }
}

@media only screen and (max-width: 1400px) {
  .about-us-row {
    height: 500px;
  }

  .about-us-text-block-desc {
    font-size: 150%;
  }
}

@media only screen and (max-width: 1300px) {
  .about-us-row {
    height: 450px;
  }
  .InfoBlockDeskCard1 {
    height: 600px;
  }
}

@media only screen and (max-width: 900px) {
  .about-us-text-block-desc {
    font-size: 110%;
  }
}

@media only screen and (max-width: 850px) {
  .InfoBlockDeskCard1 {
    height: 500px;
  }
}

@media only screen and (max-width: 700px) {
  .InfoBlockDeskCard1 {
    height: 410px;
  }
}

@media only screen and (max-width: 650px) {
  .InfoBlockDeskCard1 {
    height: 380px;
  }
}

@media only screen and (max-width: 600px) {
  .InfoBlockDeskCard1 {
    height: 350px;
  }
}

@media only screen and (max-width: 550px) {
  .about-us-text-block-title {
    font-size: 115%;
    list-style: none;
    margin-bottom: 4px;
    text-align: center;
  }
  .about-us-text-block-desc {
    font-size: 90%;
  }

  .about-us-row {
    height: 270px;
  }

  .InfoBlockDeskImageBtn {
    font-size: 20px;
  }

  .InfoBlockDeskCard1 {
    height: 250px;
  }
}

@media only screen and (max-width: 450px) {
  .about-us-text-block-title {
    font-size: 100%;
    list-style: none;
    margin-bottom: 4px;
    text-align: center;
  }

  .aboutlist {
    list-style: none;
    width: 100%;
    font-size: 90%;
    text-align: center;
  }

  .about-us-text-block-desc {
    font-size: 70%;
  }

  .about-us-row {
    height: 210px;
    display: flex;
    border-top: 0.1px solid #0071bc;
  }

  .InfoBlockDeskCard1 {
    height: 230px;
  }
}

@media only screen and (max-width: 400px) {
  .about-us-text-block-title-eng {
    font-size: 100%;
    list-style: none;
    margin-bottom: 4px;
    text-align: center;
  }

  .aboutlist {
    list-style: none;
    width: 100%;
    font-size: 90%;
    text-align: center;
  }

  .about-us-text-block-desc {
    font-size: 60%;
  }

  .about-us-row {
    height: 210px;
    display: flex;
    border-top: 0.1px solid #0071bc;
  }

  .InfoBlockDeskCard1 {
    height: 200px;
  }
}

@media only screen and (max-width: 350px) {
  .InfoBlockDeskCard1 {
    height: 190px;
  }
}
.aboutus-img1 {
  background-image: url("../../../assets/carousel-imgs/p1.png");
  object-fit: cover;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
}

.aboutus-img2 {
  background-image: url("../../../assets/carousel-imgs/p10.jpg");
  object-fit: cover;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
}

.aboutus-img3 {
  background-image: url("../../../assets/carousel-imgs/p9.jpg");
  object-fit: cover;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
}

.aboutus-img4 {
  background-image: url("../../../assets/carousel-imgs/p11.jpg");
  object-fit: cover;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
}

.aboutus-img5 {
  background-image: url("../../../assets/carousel-imgs/p8.jpg");
  object-fit: cover;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
}

.aboutus-img1 {
  background-image: url("../../../assets/carousel-imgs/p1.png");
  object-fit: cover;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
}

.aboutus-img2 {
  background-image: url("../../../assets/carousel-imgs/p10.jpg");
  object-fit: cover;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
}

.aboutus-img3 {
  background-image: url("../../../assets/carousel-imgs/p9.jpg");
  object-fit: cover;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
}

.aboutus-img4 {
  background-image: url("../../../assets/carousel-imgs/p11.jpg");
  object-fit: cover;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
}

.aboutus-img5 {
  background-image: url("../../../assets/carousel-imgs/p8.jpg");
  object-fit: cover;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
}
