.mobile-menu-logo-container {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: white;
  margin-bottom: 20px;
}

.mobile-menu-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}

.mobile-menu-icons {
  color: white;
  font-size: 20px;
  margin-right: 10px;
}

.buttonText {
  font-size: 130%;
  font-weight: 200;
  font-family: "Raleway", "sans-serif";
  color: white;
  margin: 3% 0;
}

@media only screen and (max-width: 500px) {
}
