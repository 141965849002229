.Contact-Form-Input-ar.MuiFormControl-root {
  width: 80%;
  margin: 2% 10%;
}
.Contact-Form-Input-label-ar.MuiInputLabel-formControl {
  position: absolute;
  margin-right: 30px;
  right: 0 !important;
  color: red;
  text-align: right;
}

.ContactFormBackground {
  width: 100%;
  height: 100%;
  /**background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);*/
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: white;
  border-bottom: 5px solid white;
}

.ContactFormBtn.MuiButton-root {
  width: 80%;
  margin: 2% 10%;
  background-color: #0071bc;
}

/**
.ContactFormBtn.MuiButton-root:hover {
  border: 4px solid #0071bc;
  background-color: white;
  color: #0071bc;
}
*/

.ContactFormBtnTitle {
  font-size: 18px;
  font-weight: 200;
  font-family: "Raleway", sans-serif;
}

.ContactFormTitle {
  font-weight: 400;
  font-size: 32px;
  font-family: "Raleway", sans-serif;
  color: #2e2e2e;
  margin: 0 3%;
}
/***************************/
.contact-1-row {
  position: relative;
  display: flex;
  height: 750px;
  width: 90%;
  margin: 0 5%;
}

.contact-info-box-ar {
  width: 40%;
  text-align: right;
  position: relative;
}

.Contact-Form-Box-ar {
  width: 60%;
  padding-top: 2%;
  padding: 2%;
  background-attachment: fixed;
  background-size: cover;
  height: 530px;
  margin-left: 5%;
}

.contact-info-box-box {
  text-align: center;
  width: 50%;
  margin: 0;
}

.list-column-ar {
  height: 100px;

  margin-bottom: 10px;
}

.contact-info-title-ar {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 150%;
  text-align: right;
}

.contact-info-desc-ar {
  /**font-family: "Raleway", sans-serif;*/
  font-weight: 200;
  font-size: 100%;
}

.icon-info-ar-container {
  margin-top: 15px;
  display: flex;
  float: right;
  clear: right;
  text-decoration: none;
  color: black;
}

.icon-info-ar {
  /**font-family: "Raleway", sans-serif;*/
  font-weight: 200;
  margin-left: 10px;
  font-size: 100%;
  text-align: right;
  float: right;
  clear: right;
}
.Enquiry-Form-Box-top {
  margin-bottom: 3%;
}

.map-containter {
  position: relative;
  height: 400px;
}

.map-style {
  position: relative;

  height: 50%;
  width: 100%;
  /**margin: 1% 5%;*/
  position: relative;
}

.breakline {
  display: none;
}

.icon-font.MuiSvgIcon-root {
  font-size: 100%;
}

.time-card {
  width: 60%;
  float: right;
  clear: right;
  margin-top: 1%;
}

.title-working-hours {
  float: right;
  clear: right;
  margin-top: 15px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media only screen and (max-width: 850px) {
  .title-working-hours {
    float: none;
    clear: none;
    margin-top: 0;
  }
  .Contact-Form-Box-ar {
    width: 80%;
    margin: 0 10%;
  }
  .contact-info-title-ar {
    margin-top: 0;
    text-align: center;
    font-size: 120%;
  }

  .contact-info-desc-ar {
    font-weight: 400;
    font-size: 70%;
  }

  .icon-info-ar {
    font-weight: 400;
    font-size: 70%;
  }

  .Contact-Form-Box {
    width: 80%;
    margin: auto 10%;
  }

  .contact-1-row {
    display: inline;
    height: 600px;
  }

  .contact-info-box-ar {
    display: flex;
    width: 95%;
    margin: 10% 2.5%;
    justify-content: space-evenly;
    height: 280px;
  }

  .contact-info-box-box {
    margin-left: 3%;
  }

  .icon-info-ar {
  }

  .contact-info-box-1-ar {
    width: 33%;
    text-align: center;
  }

  .contact-info-box-2-ar {
    width: 33%;
  }

  .contact-info-box-3-ar {
    width: 33%;
    text-align: center;
  }
  .list-column-ar {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 650px) {
  .contact-info-title-ar {
    font-size: 100%;
  }

  .contact-info-desc-ar {
    font-size: 60%;
  }

  .icon-info-ar {
    font-size: 60%;
  }

  .contact-info-box-ar {
    display: flex;
    width: 90%;
    margin: 3% 5%;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 500px) {
  .contact-info-title-ar {
    font-size: 100%;
  }

  .contact-info-desc-ar {
    font-size: 80%;
  }

  .icon-info-ar {
    font-size: 80%;
  }
  /**
  .contact-info-box {
    display: flex;
    width: 90%;
    margin: 3% 5%;
    justify-content: space-evenly;
  }
*/
}

@media only screen and (max-width: 520px) {
  .Contact-Form-Box {
    width: 80%;
    margin: auto 10%;
  }
  .icon-info {
    font-size: 55%;
  }

  .icon-font.MuiSvgIcon-root {
    font-size: 80%;
  }
}

@media only screen and (max-width: 450px) {
  .ContactFormBackground {
    padding-top: 20%;
  }
  .ContactFormBtn.MuiButton-root {
    width: 70%;
    margin: 2% 15%;
  }

  .ContactFormBtnTitle {
    font-size: 18px;
    font-weight: 400;
  }

  .ContactFormTitle {
    font-weight: 700;
    font-size: 26px;
    color: white;
    margin: 0 3%;
  }
}

@media only screen and (max-width: 400px) {
  /**.ContactFormBackground {
    padding-top: 24%;
  }

  .ContactFormBtnTitle {
    font-size: 15px;
    font-weight: 400;
  }

  .ContactFormTitle {
    font-weight: 700;
    font-size: 23px;
    color: white;
    margin: 0 3%;
  }
  
  .contact-info-title-ar {
    font-size: 80%;
  }

  .contact-info-desc-ar {
    font-size: 60%;
  }
*/

  .contact-info-title-ar {
    margin-top: 0;
    text-align: center;
    font-size: 95%;
  }

  .contact-info-desc-ar {
    font-weight: 400;
    font-size: 55%;
  }

  .icon-info-ar {
    font-weight: 400;
    font-size: 55%;
  }
  .contact-info-box-ar {
    width: 95%;
    margin: 3% 2.5%;
  }

  .contact-info-box-1 {
    text-align: center !important;
  }

  .contact-info-title-ar {
    text-align: center !important;
  }

  .breakline {
    display: block;
  }
  .icon-font.MuiSvgIcon-root {
    font-size: 1em;
  }
}

/*************************************/

.divider {
  /* minor cosmetics */
  display: table;
  font-size: 24px;
  text-align: center;
  width: 75%; /* divider width */
  margin: 4px auto; /* spacing above/below */
}
.divider span {
  display: table-cell;
  position: relative;
}
.divider span:first-child,
.divider span:last-child {
  width: 50%;
  top: 13px; /* adjust vertical align */
  -moz-background-size: 100% 2px; /* line width */
  background-size: 100% 2px; /* line width */
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
}
.divider span:first-child {
  /* color changes in here */
  background-image: gradient(linear, 0 0, 0 100%, from(transparent), to(#000));
  background-image: -webkit-linear-gradient(180deg, transparent, #000);
  background-image: -moz-linear-gradient(180deg, transparent, #000);
  background-image: -o-linear-gradient(180deg, transparent, #000);
  background-image: linear-gradient(90deg, transparent, #000);
}
.divider span:nth-child(2) {
  color: #000;

  width: auto;
  white-space: nowrap;
}
.divider span:last-child {
  /* color changes in here */
  background-image: gradient(linear, 0 0, 0 100%, from(#000), to(transparent));
  background-image: -webkit-linear-gradient(180deg, #000, transparent);
  background-image: -moz-linear-gradient(180deg, #000, transparent);
  background-image: -o-linear-gradient(180deg, #000, transparent);
  background-image: linear-gradient(90deg, #000, transparent);
}
