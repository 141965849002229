.mobile-menu-logo-container {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: white;
  margin-bottom: 20px;
}

.mobile-menu-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}

.mobile-menu-icons {
  color: white;
  font-size: 20px;
  margin-right: 10px;
}

.buttonText {
  font-size: 130%;
  font-weight: 200;
  font-family: "Raleway";
  color: white;
  margin: 3% 0;
}

.menuBarContainer {
  position: absolute;
  bottom: 0;
  height: 100vh;
  width: 330px;
  background-color: white;
  left: 0;
  z-index: 2200;
}
