.desknav-ar {
  position: absolute;
  bottom: 15%;
  left: 3%;
  display: flex;
  width: 33%;
  height: 12%;
  text-align: center;
  justify-content: space-evenly;
}

.nav-btn {
  position: relative;
  height: 100%;
  width: 25%;
  border-right: 1px solid rgb(92, 92, 92);
  color: rgb(92, 92, 92);
}

.nav-btn:last-child {
  border-right: none;
}

.nav-btn-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-size: 100%;
  font-family: "Raleway", sans-serif;
  font-weight: 200;
}

#home:hover,
#products:hover,
#about:hover,
#contact:hover .nav-btn-text {
  color: #0071bc;
}

@media only screen and (min-width: 1801px) {
  .desknav-ar {
    width: 22%;
  }
  .nav-btn-text {
    font-size: 130%;
  }
}

@media only screen and (max-width: 1299px) {
  .desknav-ar {
    width: 35%;
  }
}

@media only screen and (max-width: 1200px) {
  .desknav-ar {
    width: 45%;
  }
}

@media only screen and (max-width: 1000px) {
  .desknav-ar {
    width: 47%;
  }
  .nav-btn-text {
    font-size: 95%;
  }
}

/**
@media only screen and (min-width: 1801px) {
  .desknav {
    width: 22%;
  }

  .nav-btn-text {
    font-size: 130%;
  }
}

@media only screen and (min-width: 1300px) {
}

@media only screen and (max-width: 1299px) {
  .desknav {
    width: 35%;
  }
}

@media only screen and (max-width: 1200px) {
  .desknav {
    width: 45%;
  }
}

@media only screen and (max-width: 1000px) {
  .desknav {
    width: 47%;
  }
  .nav-btn-text {
    font-size: 95%;
  }
}
*/
