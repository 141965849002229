.Header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 250px;
  background-color: white;
  color: blue;
  border-bottom: 1px solid #0071bc;
  z-index: 100;
  margin-bottom: 10px;
}

.webLogo-ar {
  height: 100%;
  position: absolute;
  top: 5%;
  right: 5%;
}

.menuMobileDisplay-ar {
  display: none;
}

.menuLogo-ar.MuiSvgIcon-root {
  color: black;
  font-size: 50px;
  position: absolute;
  top: 10%;
  left: 4%;
}

.language-box-ar {
  width: 15%;
  display: flex;
  position: absolute;
  top: 10%;
  left: -4%;
  justify-content: space-evenly;
}

.language-img {
  height: 40px;
  position: absolute;
  top: 10%;
  right: 4%;
}

@media only screen and (max-width: 800px) {
  .menuDeskDisplay {
    display: none;
  }

  .webLogo-ar {
    height: 75%;
  }

  .menuMobileDisplay-ar {
    display: inline;
  }

  .menuLogo-ar.MuiSvgIcon-root {
    position: absolute;
    top: 3%;
    right: 4%;
  }

  .language-img {
    top: 80%;
  }

  .language-box-ar {
    top: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .menuDeskDisplay-ar {
    display: none;
  }

  .webLogo-ar {
    height: 75%;
  }

  .menuMobileDisplay-ar {
    display: inline;
  }

  .menuLogo-ar.MuiSvgIcon-root {
    position: absolute;
    top: 3%;
    right: 4%;
  }

  .language-img {
    top: 80%;
  }

  .language-box-ar {
    top: 80%;
  }
}

@media only screen and (max-width: 400px) {
  .language-box-ar {
    left: 4%;
  }
}
