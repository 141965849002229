.popup-content {
  background-color: transparent;
  border: none;
  height: 80%;
  width: 55%;
}

.PopupImage {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PopupContainer {
  border: none;
  height: 500px;
  width: 500px;
  z-index: 1500;
}

/**
@media only screen and (max-width: 1650px) {
  .popup-content {
    height: 65%;
    width: 55%;
  }
}

@media only screen and (max-width: 1520px) {
  .popup-content {
    height: 58%;
    width: 55%;
  }
}

@media only screen and (max-width: 1350px) {
  .popup-content {
    height: 52%;
    width: 55%;
  }
}

@media only screen and (max-width: 1000px) {
  .popup-content {
    height: 45%;
    width: 55%;
  }
}*/

@media only screen and (max-width: 750px) {
  .popup-content {
    height: 50%;
    width: 65%;
  }
}

@media only screen and (max-width: 550px) {
  .popup-content {
    height: 45%;
    width: 85%;
  }
}

@media only screen and (max-width: 450px) {
  .popup-content {
    height: 35%;
    width: 50%;
  }
}

@media only screen and (max-width: 350px) {
  .popup-content {
  }
}
