.introduction-paragraph {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 100%;
  margin: 0;
  text-align: justify;
  text-justify: inter-word;
  margin-top: 3%;
  width: 100%;
  overflow: unset;
  flex-grow: 1;
}

.info-paragraph-box {
  display: flex;
  height: 400px;
  margin: 3% 0;
  margin-left: 70px;
  margin-bottom: 120px;
}

.paragraph-container {
  margin-right: 2%;
  width: 60%;
  padding-left: 40px;
  padding-right: 140px;
}
.intro-img-container {
  width: 40%;
}
.info-character-img {
  height: 400px;
  margin: 0 10px;
  width: 100%;
}

.clients-title-text-1 {
  text-align: left;
  width: 100%;
  background-color: white;
  color: #303030;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 60px;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 80px;
  margin-top: 40px;
}

.clients-title-text-2 {
  text-align: left;
  width: 100%;
  background-color: white;
  color: #303030;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 60px;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 80px;
  margin-top: 40px;
  margin-left: 105px;
}

.clients-img {
  margin: 0% 105%;
  width: 90%;
  height: 80%;
}

.slideContainer {
  height: 600px;
  background-size: "cover";
}

.each-slide {
  height: 600px;
  background-size: "cover";
}

@media only screen and (min-width: 1800px) {
  .slideContainer {
    height: 1100px;
    background-size: "cover";
  }

  .each-slide {
    height: 1100px;
    background-size: "cover";
  }

  .info-character-img {
    height: 650px;
    margin: 0 10px;
    width: 100%;
  }

  .info-paragraph-box {
    margin-bottom: 300px;
  }

  .introduction-paragraph {
    font-size: 161%;
  }

  .paragraph-container {
    margin-top: 8%;
  }
}

@media only screen and (max-width: 1799px) {
  .info-character-img {
    height: 530px;
    margin: 0 10px;
    width: 100%;
  }
  .slideContainer {
    height: 900px;
    background-size: "cover";
  }

  .each-slide {
    height: 900px;
    background-size: "cover";
  }
}

@media only screen and (max-width: 1600px) {
  .info-character-img {
    height: 500px;
    margin: 0 10px;
    width: 100%;
  }

  .slideContainer {
    height: 700px;
    background-size: "cover";
  }

  .each-slide {
    height: 700px;
    background-size: "cover";
  }

  .introduction-paragraph {
    font-size: 110%;
  }
}

@media only screen and (max-width: 1400px) {
  .info-character-img {
    height: 450px;
    margin: 0 10px;
    width: 100%;
  }

  .slideContainer {
    height: 600px;
    background-size: "cover";
  }

  .each-slide {
    height: 600px;
    background-size: "cover";
  }
}

@media only screen and (max-width: 925px) {
  .introduction-paragraph {
    font-size: 105%;
  }

  .info-paragraph-box {
    height: 450px;
  }

  .info-character-img {
    height: 450px;
  }

  .paragraph-container {
    padding-right: 50px;
  }
}

@media only screen and (max-width: 800px) {
  .introduction-paragraph {
    font-size: 85%;
  }
  .slideContainer {
    height: 400px;
  }

  .each-slide {
    height: 400px;
  }
}

@media only screen and (max-width: 700px) {
  .introduction-paragraph {
    font-size: 75%;
  }
  .info-paragraph-box {
    height: 350px;

    margin-bottom: 250px;
  }

  .info-character-img {
    height: 350px;
    margin-top: 80px;
  }

  .clients-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 650px) {
  .slideContainer {
    height: 290px;
  }

  .each-slide {
    height: 290px;
  }
}

@media only screen and (max-width: 600px) {
  .introduction-paragraph {
    font-size: 75%;
  }
  .info-paragraph-box {
    height: 350px;

    margin-bottom: 250px;
  }
  /**
  .info-character-img {
    height: 300px;
  }
  */
  .clients-title {
    font-size: 40px;
  }

  .clients-title-text-1 {
    font-size: 40px;
  }

  .clients-title-text-2 {
    font-size: 40px;
    margin-left: 5%;
  }

  .paragraph-container {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 535px) {
  .introduction-paragraph {
    font-size: 75%;
  }

  .info-paragraph-box {
    height: 625px;
    display: inline;
    margin: 0 auto;
  }

  .info-character-img {
    height: 380px;
    width: 100%;
  }
  .clients-title {
    font-size: 30px;
  }
  .intro-img-container {
    width: 100%;
  }

  .paragraph-container {
    width: 90%;
    margin: 0 auto;
    padding: 0 10px;
  }

  .slideContainer {
    height: 230px;
  }

  .each-slide {
    height: 230px;
  }
}

@media only screen and (max-width: 440px) {
  .introduction-paragraph {
    font-size: 80%;
  }

  .info-paragraph-box {
    height: 625px;
    display: inline;
  }

  .paragraph-container {
    width: 90%;
    margin: 0 auto;
  }

  .info-character-img {
    height: 300px;
    width: 90%;
    margin: 0 auto;
  }

  .clients-title {
    font-size: 25px;
  }

  .slideContainer {
    height: 350px;
  }

  .each-slide {
    height: 350px;
  }
}
