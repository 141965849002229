.Contact-Form-Input.MuiFormControl-root {
  width: 80%;
  margin: 2% 10%;
}

.ContactFormBackground {
  width: 100%;
  height: 100%;
  /**background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);*/
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: white;
  border-bottom: 5px solid white;
}

.ContactFormBtn.MuiButton-root {
  width: 80%;
  margin: 2% 10%;
  background-color: #0071bc;
}

/**
.ContactFormBtn.MuiButton-root:hover {
  border: 4px solid #0071bc;
  background-color: white;
  color: #0071bc;
}
*/

.ContactFormBtnTitle {
  font-size: 18px;
  font-weight: 200;
  font-family: "Raleway", sans-serif;
}

.ContactFormTitle {
  font-weight: 400;
  font-size: 32px;
  font-family: "Raleway", sans-serif;
  color: #2e2e2e;
  margin: 0 3%;
}
/***************************/
.contact-1-row {
  display: flex;
  height: 750px;
  width: 90%;
  margin: 0 5%;
}

.icon-info-container {
  display: flex;
  text-decoration: none;
  color: black;
}

.contact-info-box {
  width: 40%;
  text-align: left;
  position: relative;
}

.Contact-Form-Box {
  width: 60%;
  padding-top: 2%;
  padding: 2%;
  background-attachment: fixed;
  background-size: cover;
  height: 500px;
  margin-right: 5%;
}

.contact-info-box-box {
  text-align: center;
  width: 50%;
  margin: 0;
}

.list-column {
  /**
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
  position: absolute;
  right: 0%;
}

.contact-1-row {
  width: 80%;
  margin: 0 auto;
}

.contact-info-title {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 150%;
  text-align: center;
  text-align: left;
}

.contact-info-desc {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 100%;
}

.icon-info-container {
  margin-top: 15px;
}

.icon-info {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin-left: 10px;
  font-size: 100%;
}

.icon-font {
  font-size: 15px;
}

.Enquiry-Form-Box-top {
  margin-bottom: 3%;
}

.map-containter {
  position: relative;
  height: 400px;
}

.map-style {
  position: relative;

  height: 50%;
  width: 100%;
  /**margin: 1% 5%;*/
  position: relative;
}

.breakline {
  display: none;
}

.contact-info-box-3 {
  width: 100%;
  /**
  position: absolute;
  top: 440px;
  left: 0%;*/
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media only screen and (max-width: 850px) {
  .contact-info-title {
    margin-top: 0;
    text-align: center;
    font-size: 120%;
  }

  .contact-info-desc {
    font-weight: 400;
    font-size: 70%;
  }

  .icon-info {
    font-weight: 400;
    font-size: 70%;
  }

  .icon-font.MuiSvgIcon-root {
    font-size: 100%;
  }

  .Contact-Form-Box {
    width: 80%;
    margin: auto 10%;
  }

  .contact-1-row {
    display: inline;
    height: 600px;
  }

  .contact-info-box {
    display: flex;
    width: 90%;
    margin: 3% 5%;
    justify-content: space-evenly;
    height: 280px;
  }

  .contact-info-box-box {
    margin-left: 3%;
  }

  .contact-info-box-1 {
    width: 33%;
    text-align: center;
  }

  .contact-info-box-2 {
    width: 33%;
  }

  .contact-info-box-3 {
    width: 33%;
    text-align: center;
    display: relative;
  }
}

@media only screen and (max-width: 650px) {
  .contact-info-title {
    font-size: 100%;
  }

  .contact-info-desc {
    font-size: 60%;
  }

  .icon-info {
    font-size: 60%;
  }

  .contact-info-box {
    display: flex;
    width: 90%;
    margin: 3% 5%;
    justify-content: space-evenly;
  }
}

/**
@media only screen and (max-width: 500px) {
  .contact-info-title {
    font-size: 100%;
  }

  .contact-info-desc {
    font-size: 80%;
  }

  .icon-info {
    font-size: 80%;
  }
}
*/

@media only screen and (max-width: 520px) {
  .Contact-Form-Box {
    width: 80%;
    margin: auto 10%;
  }

  .icon-info {
    font-size: 50%;
  }

  .icon-font.MuiSvgIcon-root {
    font-size: 75%;
  }
}

@media only screen and (max-width: 450px) {
  .ContactFormBackground {
    padding-top: 20%;
  }
  .ContactFormBtn.MuiButton-root {
    width: 70%;
    margin: 2% 15%;
  }

  .ContactFormBtnTitle {
    font-size: 18px;
    font-weight: 400;
  }

  .ContactFormTitle {
    font-weight: 700;
    font-size: 26px;
    color: white;
    margin: 0 3%;
  }
}

@media only screen and (max-width: 400px) {
  /**.ContactFormBackground {
    padding-top: 24%;
  }

  .ContactFormBtnTitle {
    font-size: 15px;
    font-weight: 400;
  }

  .ContactFormTitle {
    font-weight: 700;
    font-size: 23px;
    color: white;
    margin: 0 3%;
  }
  
  .contact-info-title {
    font-size: 80%;
  }

  .contact-info-desc {
    font-size: 60%;
  }
*/

  .contact-info-title {
    margin-top: 0;
    text-align: center;
    font-size: 95%;
  }

  .contact-info-desc {
    font-weight: 400;
    font-size: 50%;
  }

  .icon-info {
    font-weight: 400;
    font-size: 50%;
  }
  .contact-info-box {
    width: 95%;
    margin: 3% 2.5%;
  }

  .icon-info {
    font-size: 65%;
  }

  .contact-info-box-1 {
    text-align: center !important;
  }

  .contact-info-title {
    text-align: center !important;
  }

  .breakline {
    display: block;
  }

  .icon-font.MuiSvgIcon-root {
    font-size: 1em;
  }
}
